import * as React from "react"

function TreehouseSvg(props) {
  return (
    <svg
      className="logo-icon header-nav-item-logo-icon"
      preserveAspectRatio="xMinYMin meet"
      height={64}
      width={64}
      viewBox="0 0 30 32"
      {...props}
    >
      <path
        fill="#5fcf80"
        d="M26.5 5.9c-1.1-.6-2.8.4-3.9 2.2l-1.9 3.2c-.7 1.3-.6 3 .3 4.2l.1.1c.9 1.2 2.1 2.4 2.4 2.8.2.2.4.5.5.9.3 1.1-.3 2.3-1.5 2.6-1.1.3-2.3-.3-2.7-1.5-.1-.3-.1-.6-.1-.8.1-.5-.1-1.2-.8-2-.7-.7-2.1.7-2.5 2.1v.1c-.4 1.4-.7 2.7-.6 2.8.1.1.1.1.1.2.6 1.2.2 2.6-1 3.2-1.2.6-2.7.2-3.3-1s-.2-2.6 1-3.2c.1 0 .1-.1.2-.1s.3-.6.6-1.4c.2-.7.3-1 .4-1.3.1-.4.3-1.4.2-1.8-.1-.5-.6-.5-1.2-.2-.3.2-.9.7-1.1.9-.5.4-.9 1-1.1 1.5-.1.2-.3.5-.5.7-.9.7-2.3.6-3-.3-.7-.9-.6-2.2.3-3 .3-.2.6-.4.9-.4.5-.1 2.4-1.2 3.5-2 .2-.1.5-.4.6-.5.3-.4 0-.7-.3-.7-.9.1-1.9.2-2.1.5-.1.1-.2.3-.4.4-.9.6-2.1.4-2.7-.4-.6-.8-.4-2 .4-2.6.4-.3.8-.4 1.2-.4.7 0 2.5.4 4 .1l.4-.1c1.5-.3 3.3-1.6 4-2.9 0 0 .7-1.2 1.6-2.7.9-1.5.8-3.2-.1-3.7l-1.7-1c-.9-.5-2.3-.5-3.2 0L1.6 7C.7 7.5 0 8.6 0 9.6v12.8c0 1 .7 2.2 1.6 2.7l11.9 6.5c.9.5 2.3.5 3.2 0l11.8-6.5c.9-.5 1.6-1.7 1.6-2.7V9.6c0-1-.7-2.2-1.6-2.7-2-1 0 .1-2-1z"
      />
    </svg>
  )
}

export default TreehouseSvg
